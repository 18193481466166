import React from "react";
import { Separator } from "src/app/components/ui/separator";
import { Matrix } from "src/components/ui/card";

import "@fontsource/righteous";
import "@fontsource/quicksand";
import "@fontsource/rajdhani";

function App() {
  return (
    <div className="App flex items-center  flex-col">
      <header className="App-header mt-4 w-1/2 flex flex-col items-center">
        <h1 className="text-3xl font-bold my-4">Hi! I'm Thomas</h1>
        <p className="w-1/3 font-thin text-sm">
          This is my professional page and most likely the direct way to get
          acquainted and in touch.
        </p>
      </header>
      <p className="w-full bg-white my-12 p-8 text-2xl text-[crimson]">
        Fullstack developer / Team lead [at] Koala Interactive / RedwoodJS
        Enthusiast
      </p>
      <h2 className="title">On the Launch Pad</h2>
      <div className="grid">
        <Matrix
          title={{ children: "Koala Interactive" }}
          description={{ children: `Feature Team Lead` }}
          content={{
            children: `Koala Interactive has been building platforms and applications to have fun together. Founded 20 years ago it's one of the most resilient and independent tech company I've ever been working at.`,
          }}
          // footer={{
          //   children: (
          //     <a href="https://today.th90.net" className="text-[magenta]">
          //       find us here
          //     </a>
          //   ),
          // }}
        />
        <Matrix
          title={{ children: "Today" }}
          description={{ children: `Personal venture` }}
          content={{
            children: `Today helps you build the habits you need to achieve your goals. We don't track time, we don't organize around projects, we use the proven productivity hack of time-boxing and the super powers of reflexion to supercharge your life. Where you take it to is up to you, we only provide the tools to make the ride wild and expand your horizons. We want you to achieve everyday with focus and confidence.

`,
          }}
          footer={{
            children: (
              <a href="https://today.th90.net" className="text-[magenta]">
                find us here
              </a>
            ),
          }}
        />
        <Matrix
          title={{ children: "RedwoodJS" }}
          description={{ children: `Framework of choice` }}
          content={{
            children: `My framework of choice, an early choice at that! RedwoodJS was announced in March 2020, two weeks later a newsletter by the X-Team drops in my box and I’ve been fulltime onboard ever since. I created a few personal projects and launched my own startup with it in 2021, SportOffice, proving just how far you can get with just the right tool. A member of the startup club for a year, I joined the Core Team in June 2022. Let’s keep this one rocking! I help with triage, community, research and the occasional PR.
`,
          }}
          footer={{
            children: <a href="https://redwoodjs.com">find us here</a>,
          }}
        />
      </div>
      <h2 className="title mt-24">On the Racks</h2>
      <div className="text-left w-full">
        <span className="highlight">(selected)</span>
      </div>
      <div className="grid">
        <Matrix
          content={{
            children: `We are building the social platform to bind them all. ItList allows content creators to aggregate, advertise and sell all their expertise in curated ItLists, indexes of their knowledge. Works as well for fun makers! I joined this project because it solves a problem research engines have been unable to solve for the past decades, bringing content of value. My role is developer and framework facilitator.
`,
          }}
          footer={{
            children: <a href="https://itlist.com">find them here</a>,
          }}
          title={{ children: `ItList` }}
        />
        <Matrix
          content={{
            children: (
              <>
                <span className="highlight">Founder and CTO</span>, we built a
                marketplace to sell outdoor activities. I handled everything
                tech until we could get in production and hire our team, the
                software is still exploited by Ski Family ( brand name of DATA
                SESSION ). We presented at RedwoodJS Launch Week as part of the
                Startup event.
              </>
            ),
          }}
          title={{ children: `SportOffice` }}
        />
        <Matrix
          content={{
            children: (
              <>
                <span className="highlight">CTO</span> - initiated the technical
                transition to their long lasting project of stepping into the
                future.
              </>
            ),
          }}
          footer={{
            children: <a href="https://7speaking.com">find them here</a>,
          }}
          title={{ children: `7Speaking` }}
        />
        <Matrix
          content={{
            children: (
              <>
                <span className="highlight">Developer, Reuse Manager</span>,
                this is a company specialized in Magento. Hired as an engineer I
                eventually got in charge of probing the internal libraries we
                were creating in order to make sure we were really making the
                most of it. `
              </>
            ),
          }}
          title={{ children: `KDC` }}
        />
      </div>
      <h2 className="title mt-24">On your Agenda</h2>
      <div className="grid">
        <Matrix
          content={{
            children: `Calendly is a great tool for that, let’s hop on a call. No topic is off the table and if necessary I will make time for us to stick around as long as we need.
`,
          }}
          footer={{
            children: (
              <a href="https://calendly.com/noire-munich/30min">
                30min meeting
              </a>
            ),
          }}
          title={{ children: `Schedule a meeting` }}
        />
        <Matrix
          content={{
            children: `Code, I can do. Write, review, fix, ship, repeat! I can also help with hire and networking, make deeper connections within the RedwoodJS community.`,
          }}
          title={{
            children: `Send me on a mission`,
          }}
        />
        <Matrix
          content={{
            children: `You'll find me around here and there, but to get in touch and get more details about my career, go Linkedin:`,
          }}
          footer={{
            children: (
              <a href="https://www.linkedin.com/in/tseillan/">
                https://www.linkedin.com/in/tseillan/
              </a>
            ),
          }}
          title={{ children: `Check me out` }}
        />
      </div>
      <div className="w-full flex justify-center">
        <div className="flex flex-row w-[36rem] my-32 gap-4">
          <h1 className="text-6xl font-bold text-white">TH90</h1>
          <p className="font-thin text-sm text-white mt-2 text-justify">
            Thorium has been discovered in Løvøy as a rock by Morten Thrane
            Esmark in 1829 and is named after Thor, notorious god of thunder.
            Its radioactivity was established by Marie Curie and Gerhard Carl
            Schmidt in 1898. It has been used in many ways since its discovery,
            though as of today it has no known biological role. Th90 is an
            element of the future: it is studied as a replacement for uranium as
            its isotopes can be found in bigger quantities and it is more
            stable. It is next gen nuclear reactor material. It’s the kind of
            reliable stuff you want to put in your tanks to fly the rocket of
            your startup all the way above the clouds.
          </p>
        </div>
      </div>
      <Separator className="mt-12" />
      <div className="footer text-8xl my-12 text-ring">
        TH90 LLC, registered in Delaware 2022
      </div>
    </div>
  );
}

export default App;
