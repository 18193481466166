import { cn } from "src/lib/utils";
import * as React from "react";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "card max-w-[30rem] min-w-min rounded-lg border bg-card text-card-foreground shadow-sm",
      className
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-secondary", className)}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0 text-ring text-justify font-[rajdhani]", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

interface CardProps {
  title: Parameters<typeof CardTitle>[0];
  card?: Parameters<typeof Card>[0];
  content?: Parameters<typeof CardContent>[0];
  description?: Parameters<typeof CardDescription>[0];
  footer?: Parameters<typeof CardFooter>[0];
  header?: Parameters<typeof CardHeader>[0];
}

const Matrix: React.FC<React.PropsWithChildren<CardProps>> = (props) => (
  <Card {...props?.card}>
    {(props.title?.children || props?.description?.children) && (
      <CardHeader {...props?.header}>
        {props?.title?.children && <CardTitle {...props.title} />}
        {props?.description?.children && (
          <CardDescription {...props.description} />
        )}
      </CardHeader>
    )}
    {props?.content?.children && <CardContent {...props.content} />}
    {props?.footer?.children && <CardFooter {...props.footer} />}
  </Card>
);

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
  Matrix
};
